import axios from 'axios';
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

interface IFunnelTrack {
  ip: string;
  page: string;
  action: string;
  origin: string;
  country: string;
}

interface FunnelTrackContextData {
  funnelsTracks: IFunnelTrack[];
  setFunnelTrack(funnelTrack: IFunnelTrack): void;
  ip: string;
  origin: string;
  country: string;
}

export const FunnelTrackContext = createContext<FunnelTrackContextData>(
  {} as FunnelTrackContextData
);

export const FunnelTrackProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [ip, setIp] = useState('0.0.0.0');
  const [origin, setOrigin] = useState('');
  const [country, setCountry] = useState('En');
  const [funnelsTracks, setFunnelsTracks] = useState<IFunnelTrack[]>(() => {
    const funnelTrackData = localStorage.getItem('@Wiserr:funnelsTracks');
    if (funnelTrackData) {
      return JSON.parse(funnelTrackData);
    }

    return [];
  });

  useEffect(() => {
    localStorage.setItem(
      '@Wiserr:funnelsTracks',
      JSON.stringify(funnelsTracks)
    );

    // window.addEventListener('beforeunload', (event) => {
    //   event.preventDefault();
    //   // eslint-disable-next-line no-param-reassign
    //   event.returnValue = '';
    //   localStorage.setItem('@Wiserr:funnelsTracks', JSON.stringify([]));
    // });

    return () => {
      localStorage.setItem('@Wiserr:funnelsTracks', JSON.stringify([]));
    };
  }, [funnelsTracks]);

  useEffect(() => {
    if (location.search.includes('facebook')) {
      setOrigin('social media');
    } else if (location.search.includes('ads')) {
      setOrigin('ads');
    } else {
      setOrigin('direct');
    }

    axios.get('https://geolocation-db.com/json').then((response) => {
      setIp(response.data.IPv4);
      setCountry(response.data.country_code);
    });
  }, [location.search]);

  const handleSetFunnelTrack = useCallback((funnelTrack: IFunnelTrack) => {
    setFunnelsTracks((state) => [...state, funnelTrack]);
  }, []);

  return (
    <FunnelTrackContext.Provider
      value={{
        funnelsTracks,
        setFunnelTrack: handleSetFunnelTrack,
        ip,
        origin,
        country,
      }}
    >
      {children}
    </FunnelTrackContext.Provider>
  );
};

export function useFunnelTrack(): FunnelTrackContextData {
  const context = useContext(FunnelTrackContext);

  if (!context) {
    throw new Error(
      'useFunnelTrack must be used within an FunnelTrackProvider'
    );
  }

  return context;
}
