import React from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import { LanguageProvider } from './Language';
import { FunnelProvider } from './Funnel';
import { FunnelTrackProvider } from './FunnelTrack';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const AppProvider: React.FC = ({ children }) => (
  <Elements stripe={stripePromise}>
    <LanguageProvider>
      <FunnelTrackProvider>
        <FunnelProvider>{children}</FunnelProvider>
      </FunnelTrackProvider>
    </LanguageProvider>
  </Elements>
);

export default AppProvider;
