import styled, { css } from 'styled-components';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header`
  top: 0;
  background: transparent;
  z-index: 100;
  width: 100%;
  background: #f5f3ed;

  .logo {
    pointer-events: none;
    user-select: none;
    color: #000;
    font-family: 'Quicksand';
    font-size: 50px;
    font-weight: 700;
    line-height: 134.078%;
    letter-spacing: 4.5px;
  }

  .menu button {
    color: #171616;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 22px;
    font-weight: 500;
    line-height: 134.078%;
    letter-spacing: 1.98px;
    background: transparent;
    border: none;
    width: 111px;
    :hover {
      font-weight: 700 !important;
    }
  }

  .menu .active-students-creators {
    font-weight: 700 !important;
  }

  .menu .active-hr {
    border-top: 2px solid #ffae52;
    width: 50%;
    margin: 0 auto;
  }

  .create {
    display: flex;
    align-items: center;
    color: #171616;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 18px;
    font-weight: 700;
    line-height: 134.078%;
    letter-spacing: 1.62px;
    border-radius: 21px;
    background: #fff;
    border: none;
    box-shadow: 1px 12px 30px 0px rgba(0, 0, 0, 0.05);
    padding: 15px 21px;
    text-decoration: none;
    img {
      margin-right: 5px;
      width: 16px;
      height: 18px;
      filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
    }
  }

  @media screen and (max-width: 991px) {
    position: fixed;
    background: #f5f3ed;

    .logo {
      font-size: 40px;
      line-height: 50px;
    }
  }
  @media screen and (min-width: 1500px) {
    .container-xxl {
      max-width: 1440px !important;
    }
  }
`;
export const Menu = styled.div<IMenuProps>`
  > button {
    width: 30px;
    height: 30px;

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: #131313;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        width: 100%;
        margin: 6px 0 6px auto;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -20px;
          }
        `}
    }
  }

  @media screen and (max-width: 991px) {
    .position {
      position: absolute;
    }
    .overflow {
      ::-webkit-scrollbar {
        background-color: #fff !important;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #fff !important;
      }
    }

    .menu-group {
      width: 100%;
      height: 100vh;
      background: #f5f3ed;
      transition-duration: 0.4s;
      top: 58px;
      left: ${(props) => (props.active ? '0' : '1000%')};
    }
  }

  @media screen and (min-height: 800px) {
  }

  @media screen and (max-width: 500px) {
  }
`;
