import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

// import { Container } from './styles';
import Input from '~/components/Input';

const loader = new Loader({
  apiKey: 'AIzaSyBtxpDF-NEMPUESuZhEDKnLwwWYgpE0Xto',
  version: 'weekly',
  libraries: ['places'],
});

export interface IAddress {
  number: string;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  complement: string;
}

interface IInputAddressProps {
  name: string;
  onChange?(address: IAddress): void;
  value?: string;
  placeholder?: string;
  title?: string;
  className?: string;
}

const InputAddress: React.FC<IInputAddressProps> = ({
  name,
  onChange,
  value,
  placeholder,
  title,
  className,
}) => {
  const [type, setType] = useState('text');
  const [valueData, setValue] = useState(value);
  useEffect(() => {
    loader.load().then(() => {
      const input = document.getElementById(name) as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener('places_changed', () => {
        if (input) {
          input.focus();
          input.blur();
        }
        const places = searchBox.getPlaces();

        if (places && places.length > 0) {
          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              console.log('Returned place contains no geometry');
            }

            if (
              place.address_components &&
              place.address_components.length > 0
            ) {
              const address = {
                number: '',
                street: '',
                neighborhood: '',
                city: '',
                state: '',
                country: '',
                zipCode: '',
                complement: '',
              };

              place.address_components.forEach((addressComponent) => {
                if (addressComponent.types) {
                  switch (addressComponent.types[0]) {
                    case 'street_number':
                      address.number = addressComponent.long_name;
                      break;
                    case 'route':
                      address.street = addressComponent.long_name;
                      break;
                    case 'sublocality_level_1':
                      if (address.neighborhood.length > 0) {
                        address.complement += `${addressComponent.long_name}, `;
                      } else {
                        address.neighborhood = addressComponent.long_name;
                      }
                      break;
                    case 'neighborhood':
                      if (address.neighborhood.length > 0) {
                        address.complement += `${address.neighborhood}, `;
                      }

                      address.neighborhood = addressComponent.long_name;
                      break;
                    case 'administrative_area_level_2':
                      if (address.city.length > 0) {
                        address.complement += `${addressComponent.long_name}, `;
                      } else {
                        address.city = addressComponent.long_name;
                      }
                      break;
                    case 'locality':
                      if (address.city.length > 0) {
                        address.complement += `${address.city}, `;
                      } else if (parseInt(addressComponent.long_name, 10)) {
                        address.complement += `${addressComponent.long_name}, `;
                      } else {
                        address.city = addressComponent.long_name;
                      }

                      break;
                    case 'administrative_area_level_1':
                      address.state = addressComponent.long_name;
                      break;
                    case 'country':
                      address.country = addressComponent.long_name;
                      break;
                    case 'postal_code':
                      address.zipCode = addressComponent.long_name;
                      break;
                    default:
                      address.complement += `${addressComponent.long_name}`;
                  }
                }
              });
              const complement = address.complement.split(', ');
              if (!(complement.length > 1 && complement[1].length > 0)) {
                // eslint-disable-next-line prefer-destructuring
                address.complement = complement[0];
              }

              if (onChange) {
                onChange(address);
              }
            }
          });
        }
      });
    });
  }, [name, onChange]);

  const handleFocus = useCallback(() => {
    setType('url');
  }, []);

  const handleBlur = useCallback(() => {
    setType('text');
  }, []);

  const handleKeyPress = useCallback((e) => {
    const keyPressed = e.keyCode || e.which || e.charCode;
    if (keyPressed === 13) {
      e.target.blur();
      setType('text');
    }
  }, []);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <>
      <Input
        type={type}
        name={name}
        id={name}
        autoComplete="nope"
        onFocus={handleFocus}
        onBlur={handleBlur}
        title={title}
        placeholder={placeholder || ''}
        onKeyDown={handleKeyPress}
        onChange={handleChange}
        value={value}
        className={className}
      />
      <Input
        type="hidden"
        name={name}
        value={valueData || value}
        className="d-none"
        showError={false}
      />
    </>
  );
};

export default InputAddress;
