import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineChevronRight } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js';

import getValidationsErrors from '~/utils/getValidationsErrors';

import { Modal } from './styles';
import logo from '~/assets/logo/logo-white-modal.svg';
import checkWhite from '~/assets/icons/check-white.svg';
import safe from '~/assets/defaults/safe-modal.png';
import money from '~/assets/defaults/money-modal.png';
import proteced from '~/assets/defaults/proteced.png';

import Input from '../Input';
import InputCheckbox from '../InputCheckbox';
import { formatPrice } from '~/utils/format';
import InputAddress, { IAddress } from '../InputAddress';
import api from '~/services/api';
import apiNew from '~/services/apiNew';

interface IPrice {
  id: string;
  name: string;
  value: number;
  type: string;
  period: number;
  file: {
    file_url: string;
  };
}

interface IProduct {
  prices: IPrice[];
}

interface IModal {
  show: boolean;
  planSelected: string;
  onClose: () => void;
}

interface IFormData {
  name: string;
  email: string;
  password: string;
  recommend: string;
  cardNumber: string;
  expiration: string;
  cvc: string;
}

interface IPlan {
  id: string;
  thumbnail: string;
  name: string;
  price: string;
  type: string;
  period: number;
  planPeriod: string;
}

interface IPayButtonProps {
  customer_id: string;
  student_id: string;
  price_id: string;
  type: string;
  amount: number;
  period: number;
  interval: string;
  onSuccessfulPayment(): void;
}

const PayButton: React.FC<IPayButtonProps> = ({
  customer_id,
  student_id,
  price_id,
  type,
  amount,
  period,
  interval,
  onSuccessfulPayment,
}) => {
  const stripe = useStripe();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [elements, setElements] = useState<any>(undefined);

  const handleConfirm = useCallback(
    async (event) => {
      if (stripe && elements) {
        const { error: submitError } = await elements.submit();
        if (submitError) {
          Swal.fire('Opss...', '', 'error');
          return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: event.billingDetails,
          },
        });

        if (error) {
          Swal.fire('Opss...', '', 'error');
        } else if (paymentMethod) {
          const orderFormData = {
            customer_id,
            student_id,
            product_id: process.env.REACT_APP_WISERR_PLANS,
            price_id,
            payment_method_id: paymentMethod.id,
            amount,
            payment_method: paymentMethod.type,
            period,
            type,
            interval,
          };

          await apiNew.post('/orders', orderFormData);

          onSuccessfulPayment();
        }
      }
    },
    [
      amount,
      customer_id,
      elements,
      interval,
      period,
      price_id,
      stripe,
      student_id,
      type,
      onSuccessfulPayment,
    ]
  );

  useEffect(() => {
    if (stripe) {
      const newElements = stripe.elements({
        mode: type === 'lifetime' ? 'payment' : 'subscription',
        amount: amount * 100,
        currency: 'usd',
        paymentMethodCreation: 'manual',
      });

      setElements(newElements);
    }
  }, [amount, stripe, type, customer_id, student_id]);

  useEffect(() => {
    if (stripe && elements) {
      const expressCheckout = elements.getElement('expressCheckout');

      if (!expressCheckout) {
        const expressCheckoutElement = elements.create('expressCheckout', {
          paymentMethods: {
            applePay: 'always',
            googlePay: 'auto',
            amazonPay: 'never',
            link: 'auto',
            paypal: 'never',
          },
        });

        expressCheckoutElement.on('confirm', handleConfirm);

        expressCheckoutElement.mount('#payment-button');
      }
    }
  }, [elements, handleConfirm, stripe]);

  return <div id="payment-button" />;
};

const ModalBuyPlan: React.FC<IModal> = ({ show, planSelected, onClose }) => {
  const formRef = useRef<FormHandles>(null);
  const formPaymentRef = useRef<FormHandles>(null);
  const stripe = useStripe();
  const elements = useElements();
  const [checked, setChecked] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({} as IPlan);
  const [othersPlans, setOthersPlans] = useState(false);
  const [isYourDetails, setIsYourDetails] = useState(true);
  const [address, setAddress] = useState({} as IAddress);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [studentId, setStudentId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [customerId, setCustomerId] = useState('');

  useEffect(() => {
    apiNew
      .get<IProduct>(`products/${process.env.REACT_APP_WISERR_PLANS}`)
      .then((response) => {
        const data = response.data.prices.map<IPlan>((priceData) => {
          let planPeriod = 'Monthly';

          if (priceData.type === 'subscription' && priceData.period === 12) {
            planPeriod = 'Yearly';
          }

          if (priceData.type === 'lifetime') {
            planPeriod = 'One-time';
          }

          return {
            id: priceData.id,
            thumbnail: priceData.file.file_url,
            name: priceData.name,
            price: formatPrice(priceData.value).replace('.00', ''),
            type: priceData.type,
            period: priceData.period,
            planPeriod,
          };
        });

        const plan = data.find((planData) =>
          planData.name.toLowerCase().includes(planSelected)
        );

        if (plan) {
          setSelectedPlan(plan);
        }
        setPlans(data);
      });
  }, [planSelected]);

  const handleChangeAddress = useCallback((addressData) => {
    setAddress(addressData);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Full name is required'),
          email: Yup.string().required('Email is required'),
          password: Yup.string().required('Password is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          name: data.name,
          email: data.email,
          password: data.password,
        };

        if (email !== data.email) {
          const response = await api.post(
            '/students/sessions/create',
            formData
          );

          setStudentId(response.data.id);
          setUserName(response.data.name);
          setCustomerId(response.data.customer_id);
        }

        setEmail(data.email);
        setPassword(data.password);
        setIsYourDetails(false);
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);

          formRef.current?.setErrors(errors);
        } else if (Object.keys(err.response).length > 0) {
          if (err.response.data.message === 'Email address already used') {
            formRef.current?.setErrors({ email: err.response.data.message });
          }
        } else {
          Swal.fire('Opss...', '', 'error');
        }
      }
    },
    [email]
  );

  const handleSuccessfulPayment = useCallback(async () => {
    const response = await api.post('/students/sessions', {
      email,
      password,
    });

    const responseData = await api.post('/students/sessions/set-data', {
      student_id: studentId,
      data: JSON.stringify(response.data),
    });

    const { token } = responseData.data;

    window.location.href = `${process.env.REACT_APP_STUDENTS_URL}/auto-login/${token}`;
  }, [email, password, studentId]);

  const handleSubmitPayment = useCallback(
    async (data: IFormData) => {
      try {
        formPaymentRef.current?.setErrors({});
        const schema = Yup.object().shape({
          number: Yup.string().when('$numberCheck', {
            is: (numberCheck: boolean) => !numberCheck,
            then: Yup.string()
              .url('Number is required')
              .required('Number is required'),
            otherwise: Yup.string(),
          }),
          street: Yup.string().when('$streetCheck', {
            is: (streetCheck: boolean) => !streetCheck,
            then: Yup.string()
              .url('Street is required')
              .required('Street is required'),
            otherwise: Yup.string(),
          }),
          neighborhood: Yup.string().when('$neighborhoodCheck', {
            is: (neighborhoodCheck: boolean) => !neighborhoodCheck,
            then: Yup.string()
              .url('Neighborhood is required')
              .required('Neighborhood is required'),
            otherwise: Yup.string(),
          }),
          city: Yup.string().when('$cityCheck', {
            is: (cityCheck: boolean) => !cityCheck,
            then: Yup.string()
              .url('City is required')
              .required('City is required'),
            otherwise: Yup.string(),
          }),
          state: Yup.string().when('$stateCheck', {
            is: (stateCheck: boolean) => !stateCheck,
            then: Yup.string()
              .url('State is required')
              .required('State is required'),
            otherwise: Yup.string(),
          }),
          country: Yup.string().when('$countryCheck', {
            is: (countryCheck: boolean) => !countryCheck,
            then: Yup.string()
              .url('Country is required')
              .required('Country is required'),
            otherwise: Yup.string(),
          }),
          zipCode: Yup.string().when('$zipCodeCheck', {
            is: (zipCodeCheck: boolean) => !zipCodeCheck,
            then: Yup.string()
              .url('Zip Code is required')
              .required('Zip Code is required'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            numberCheck: Object.keys(address).length > 0 && address.number,
            streetCheck: Object.keys(address).length > 0 && address.street,
            neighborhoodCheck:
              Object.keys(address).length > 0 && address.neighborhood,
            cityCheck: Object.keys(address).length > 0 && address.city,
            stateCheck: Object.keys(address).length > 0 && address.state,
            countryCheck: Object.keys(address).length > 0 && address.country,
            zipCodeCheck: Object.keys(address).length > 0 && address.zipCode,
          },
        });

        const addressFormData = {
          student_id: studentId,
          zip_code: address.zipCode,
          number: address.number,
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state,
          country: address.country,
          street: address.street,
          complement: address.complement,
        };

        await api.post('/adresses', addressFormData);

        let creditCardId = '';
        let cardNumber: StripeCardNumberElement | null = null;
        if (elements) {
          cardNumber = elements.getElement(CardNumberElement);
        }
        if (cardNumber) {
          const result = await stripe?.createToken(cardNumber);

          if (result && result.token) {
            const formCreditCard = {
              card_id: result.token.card?.id,
              token: result.token.id,
              student_id: studentId,
              name: userName,
              number: result.token.card?.last4,
              expirity: `${result.token.card?.exp_month}/${result.token.card?.exp_year}`,
              brand: result.token.card?.brand,
            };

            const responseCreditCard = await apiNew.post(
              '/credit-cards',
              formCreditCard
            );
            creditCardId = responseCreditCard.data.id;
          }
        }

        let interval = '';
        let period = 1;

        if (selectedPlan.type === 'subscription') {
          if (selectedPlan.period === 12) {
            interval = 'year';
            period = selectedPlan.period / 12;
          } else {
            interval = 'month';
            period = selectedPlan.period;
          }
        }

        const orderFormData = {
          customer_id: customerId,
          student_id: studentId,
          product_id: process.env.REACT_APP_WISERR_PLANS,
          price_id: selectedPlan.id,
          creditCard_id: creditCardId,
          amount: parseFloat(
            selectedPlan.price.replace(',', '').replace('$', '')
          ),
          payment_method: 'Credit Card',
          period,
          type: selectedPlan.type,
          interval,
        };

        await apiNew.post('/orders', orderFormData);

        handleSuccessfulPayment();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
          const keys = Object.keys(errors);

          if (keys.some((key) => key === 'street')) {
            errors.address = errors.street;
          } else if (keys.some((key) => key === 'street')) {
            errors.address = errors.street;
          } else if (keys.some((key) => key === 'neighborhood')) {
            errors.address = errors.neighborhood;
          } else if (keys.some((key) => key === 'city')) {
            errors.address = errors.city;
          } else if (keys.some((key) => key === 'state')) {
            errors.address = errors.state;
          } else if (keys.some((key) => key === 'number')) {
            errors.address = errors.number;
          } else if (keys.some((key) => key === 'country')) {
            errors.address = errors.country;
          }
          formPaymentRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', '', 'error');
        }
      }
    },
    [
      address,
      customerId,
      elements,
      selectedPlan,
      stripe,
      studentId,
      userName,
      handleSuccessfulPayment,
    ]
  );

  const handleClickBack = useCallback(() => {
    if (!isYourDetails) {
      setIsYourDetails(true);
    } else {
      onClose();
      setOthersPlans(false);
    }
  }, [isYourDetails, onClose]);

  const elementOptions = useMemo<StripeCardNumberElementOptions>(
    () => ({
      style: {
        base: {
          color: '#606060',
          fontSize: '16px',
          '::placeholder': { color: '#b4b4b4' },
          backgroundColor: 'transparent',
          fontFamily: 'inherit',
        },
        invalid: { color: '#c53030' },
      },
    }),
    []
  );

  return (
    <Modal
      show={show}
      onHide={() => {
        onClose();
        setOthersPlans(false);
        setIsYourDetails(true);
      }}
      className="modal-plans"
      centered
      size="xl"
    >
      <Modal.Body className="bg-modal">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-6 order-1 order-lg-0">
              <div className="left-side position-relative mb-3 mb-sm-0">
                <div
                  className={`${
                    othersPlans ? 'd-flex' : 'd-none'
                  } bg-change-plan  flex-column justify-content-center align-items-center`}
                >
                  {plans.map((plan) => (
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedPlan(plan);
                        setOthersPlans(false);
                      }}
                      className={`${
                        plan.id === selectedPlan.id ? 'current-plan' : ''
                      } d-flex align-items-center card-choose my-1`}
                    >
                      <img src={plan.thumbnail} alt={plan.name} />

                      <div className="ml-3 ml-lg-4">
                        <h4 className="text-left mb-1 mb-lg-2">{plan.name}</h4>
                        <p className="text-left mb-0">
                          {plan.price} {plan.planPeriod}
                        </p>
                      </div>
                      {plan.id === selectedPlan.id ? (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                          Current <br className="d-lg-none" />
                          Plan
                        </span>
                      ) : (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 choose-color">
                          Choose <br className="d-lg-none" />
                          Plan
                        </span>
                      )}
                    </button>
                  ))}
                </div>

                <div className="bg d-flex justify-content-center align-items-center position-relative">
                  <img src={logo} alt="Wiserr" className="mb-5" />
                  {Object.keys(selectedPlan).length > 0 && (
                    <button
                      type="button"
                      onClick={() => setOthersPlans(true)}
                      className={`${
                        othersPlans ? 'opacity-0' : 'opacity-1'
                      } d-flex align-items-center card-change current-plan my-1`}
                    >
                      <img
                        src={selectedPlan.thumbnail}
                        alt={selectedPlan.name}
                      />

                      <div className="ml-3 ml-lg-4 ">
                        <h4 className="text-left mb-1 mb-lg-2">
                          {selectedPlan.name}
                        </h4>
                        <p className="text-left mb-0">
                          {selectedPlan.price} {selectedPlan.planPeriod}
                        </p>
                      </div>

                      <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                        Change <br className="d-lg-none" />
                        Plan
                      </span>
                    </button>
                  )}
                </div>
                <div className="px-4 px-lg-5 bg-left-bottom">
                  <h2 className="text-center">Simple pricing for everyone</h2>
                  <h3 className="text-center px-lg-5">
                    We design learning experiences that fit in your pocket and
                    help you grow your bottom line.
                  </h3>
                  <p className="d-flex align-items-center px-4 px-lg-5">
                    <img src={checkWhite} alt="Check" className="mr-4" />
                    Content curated for entrepreneurs
                  </p>
                  <p className="d-flex align-items-center px-4 px-lg-5">
                    <img src={checkWhite} alt="Check" className="mr-4" />
                    Trending topcis
                  </p>
                  <p className="d-flex align-items-center px-4 px-lg-5">
                    <img src={checkWhite} alt="Check" className="mr-4" />
                    Resources & Support
                  </p>
                  <p className="d-flex align-items-center mb-0 px-4 px-lg-5">
                    <img src={checkWhite} alt="Check" className="mr-4" />
                    7-Day money-back guarantee
                  </p>
                  <div className="text-center mt-5 pt-5 pb-4">
                    <Link
                      to={`${process.env.PUBLIC_URL}/privacy-policy`}
                      className="mx-1 mx-lg-3"
                    >
                      Privacy Policy
                    </Link>{' '}
                    |
                    <Link
                      to={`${process.env.PUBLIC_URL}/terms-of-use`}
                      className="mx-1 mx-lg-3"
                    >
                      Terms of Service
                    </Link>{' '}
                    |
                    <Link
                      to={`${process.env.PUBLIC_URL}/copyright-notice`}
                      className="mx-1 mx-lg-3"
                    >
                      Security
                    </Link>{' '}
                    |
                    <Link
                      to={`${process.env.PUBLIC_URL}/cookie-policy`}
                      className="mx-1 mx-lg-3"
                    >
                      Cookies
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="right-side">
                <div className="mt-3 mb-4 d-flex">
                  <p className="ml-4 ml-lg-0 d-flex align-items-center mb-0">
                    <span
                      className={`${
                        isYourDetails ? 'text-black font-weight-bold' : ''
                      }`}
                    >
                      Your details
                    </span>
                    <span>
                      <MdOutlineChevronRight size={20} color="#000" />
                    </span>
                    <span
                      className={`${
                        !isYourDetails ? 'text-black font-weight-bold' : ''
                      }`}
                    >
                      Payment
                    </span>
                  </p>
                  <button
                    onClick={handleClickBack}
                    type="button"
                    className="btn-back ml-auto mr-4 mr-lg-5"
                  >
                    Back
                  </button>
                </div>
                <div className="personal">
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={isYourDetails ? 'd-block' : 'd-none'}
                  >
                    <h2>Welcome aboard!</h2>
                    <p className="mb-5">
                      Enter your information below to get started
                    </p>
                    <span className="d-block mb-3">Full Name</span>
                    <Input name="name" className="mb-4 input" />
                    <span className="d-block mb-3">Email</span>
                    <Input name="email" type="email" className="mb-4 input" />
                    <span className="d-block mb-3">Password</span>
                    <Input
                      name="password"
                      type="password"
                      className="mb-4 input"
                    />
                    <span className="d-block mb-3">
                      Who should we thank for recommending us?{' '}
                      <span className="font-weight-normal">(Optional)</span>
                    </span>
                    <Input name="recommend" className="mb-4 input" />
                    <InputCheckbox
                      type="checkbox"
                      name="terms"
                      className="justify-content-start"
                      options={[
                        {
                          labelHtml: true,
                          label:
                            'I have read and agree to the terms of service',
                          value:
                            'I have read and agree to the terms of service',
                          selected: checked,
                        },
                      ]}
                      onChange={() => setChecked(!checked)}
                    />
                    <button
                      type="submit"
                      className={`${
                        checked ? 'opacity-100' : 'pe-none opacity-50'
                      } btn-continue`}
                    >
                      Continue
                    </button>
                    <p className="login text-center mt-4">
                      Already have an account?{' '}
                      <a
                        className="font-weight-bold"
                        href="https://app.wiserr.io/sign-up"
                      >
                        Login
                      </a>
                    </p>
                  </Form>
                  <Form
                    ref={formPaymentRef}
                    onSubmit={handleSubmitPayment}
                    className={!isYourDetails ? 'd-block' : 'd-none'}
                  >
                    <h2>Almost there</h2>
                    <p>Enter your payment details below</p>
                    <div className="mb-3">
                      {Object.keys(selectedPlan).length > 0 && (
                        <PayButton
                          customer_id={customerId}
                          student_id={studentId}
                          price_id={selectedPlan.id}
                          type={selectedPlan.type}
                          amount={parseFloat(
                            selectedPlan.price.replace('$', '').replace(',', '')
                          )}
                          period={selectedPlan.period}
                          interval={
                            selectedPlan.period === 12 ? 'year' : 'month'
                          }
                          onSuccessfulPayment={handleSuccessfulPayment}
                        />
                      )}
                      {/* <button
                          type="button"
                          className="mb-3 btn-apple p-3 h4 d-flex align-items-start justify-content-center text-white w-100"
                        >
                          <FaApple color="#fff" size={25} /> Pay
                        </button> */}
                    </div>
                    <span className="d-block mb-3">Credit Card Number</span>
                    <div className="mb-4 input input-stripe">
                      <CardNumberElement options={elementOptions} />
                    </div>
                    <div className="d-lg-flex">
                      <div className="pr-lg-3 w-100 w-lg-50">
                        <span className="d-block mb-3">Expiration</span>
                        <div className="mb-4 input input-stripe">
                          <CardExpiryElement options={elementOptions} />
                        </div>
                      </div>
                      <div className="pl-lg-3 w-100 w-lg-50">
                        <span className="d-block mb-3">CVC*</span>
                        <div className="mb-4 input input-stripe">
                          <CardCvcElement options={elementOptions} />
                        </div>
                      </div>
                    </div>
                    <span className="d-block mb-3">Billing Address</span>
                    <InputAddress
                      name="address"
                      className="mb-4 input"
                      onChange={handleChangeAddress}
                    />

                    <p className="d-flex justify-content-between">
                      <span className="font-weight-normal">Subtotal</span>
                      <span className="font-weight-normal">
                        {Object.keys(selectedPlan).length > 0
                          ? formatPrice(
                              parseFloat(
                                selectedPlan.price
                                  .replace('$', '')
                                  .replace(',', '')
                              )
                            )
                          : formatPrice(0)}
                      </span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span className="font-weight-normal">Special Offer</span>
                      <span className="font-weight-normal">-</span>
                    </p>
                    <hr />
                    <p className="total d-flex justify-content-between">
                      <span>Order Total:</span>
                      <span>
                        {Object.keys(selectedPlan).length > 0
                          ? formatPrice(
                              parseFloat(
                                selectedPlan.price
                                  .replace('$', '')
                                  .replace(',', '')
                              )
                            )
                          : formatPrice(0)}
                      </span>
                    </p>
                    <button type="submit" className={` btn-continue`}>
                      Pay Now
                    </button>
                    <div className="d-flex my-4 imgs-footer">
                      <img src={safe} alt="Safe" />
                      <img src={money} alt="Money" />
                      <img src={proteced} alt="Proteced" />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBuyPlan;
