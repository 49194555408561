import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  > div {
    background: transparent;
    border-radius: 15px;
    border: 1.5px solid #cbccce;
    padding: 17px 10px;
    width: 100%;
    display: flex;
    align-items: center;

    div {
      width: 100%;
      position: relative;

      .button-show {
        position: absolute;
        top: -6px;
        right: -10px;
        width: 60px;
        text-align: center;
        background: none;
        border: 0;
        border-left: 1px solid rgba(224, 224, 224, 0.8);
        padding: 6px 10px 5px;
        color: #cbccce;
      }
    }

    input {
      width: 100%;
      flex: 1;
      background: transparent;
      border: 0;
      color: #18191a;
      transition-duration: 0.2s;

      ::placeholder {
        color: #18191a;
      }
    }

    svg {
      margin-right: 16px;
      color: #666360;
      transition-duration: 0.2s;
    }

    ${(props) =>
      props.isErrored &&
      css`
        border-color: #c53030;
      `}
  }
`;
